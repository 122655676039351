import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import user1 from "../../images/user_1.svg";
import user2 from "../../images/user_2.svg";
import userLogin from "../../images/user-login-icon.svg";
import closeIcon from "../../images/close.svg";
import login from "../../images/login.jpg";
import signup from "../../images/singup.jpg";
import apple from "../../images/apple.svg";
import google from "../../images/google.png";
import googleIcon from "../../images/google-icon.png";
import appleicon from "../../images/apple-singup.png";
import facebook from "../../images/facebook.svg";
import twitter from "../../images/twitter.svg";
import ticket from "../../images/ticket-icon.svg";
import heart from "../../images/HEART ICON.svg";
import logout from "../../images/logout.svg";
import calendarIcon from "../../images/calendar_ic.svg";
import locationIcon from "../../images/location-pin.svg";
import clockIcon from "../../images/clock.svg";
import loading from "../../images/loading.gif";
import appStore from "../../images/app-store.svg";
import playStore from "../../images/google-play-store.svg";
import Helper from '../../services/helper';
import { registerRequest, loginRequest, forgotPwdRequest, getFavouriteEvents, googleLoginRequest, facebookLoginRequest, removeFromFavourite, sendSMSRequest, resendVerificationMailRequest } from "../../redux/actions/actions";
//import { GoogleLogin } from 'react-google-login';
import { FacebookLogin } from 'react-facebook-login-lite';
import qrcode1 from '../../images/organize-app.png';
import qrcode2 from '../../images/booking-app.png';
import { GoogleLogin } from '@react-oauth/google';
import AppleLogin from "react-apple-login";
import jwt from "jsonwebtoken";
import mainAppQRCode from "../../images/mainAppQRCode.png";
import organizerQRCode from "../../images/organizerQRCode.png";
const { v4: uuidv4 } = require('uuid');

class UserActions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: (Helper.IsAuthenticated()) ? Helper.GetCurrentUser().UserId : null,
            show: false,
            showSignup: false,
            showSignin: false,
            showContact: false,
            unmute: false,
            navClass: false,
            email: '',
            password: '',
            username: '',
            surname: '',
            showForgotPwdPage: false,
            errorMessage: '',
            googleClientId: '',
            facebookClientId: '',
            showRemoveFavProgress: false,
            showGetApp: false,
            mobileNo: '',
            appStoreURL: '',
            playStoreURL: '',
            accountValidationError: false,
            loadFavEvents: true,
            feId: 0,
            events: []
        };
    }

    componentDidMount() {

        document.addEventListener('AppleIDSignInOnSuccess', (event) => {
            // Handle successful response.
            //console.log(event.detail.data);
        });


        // Listen for authorization failures.
        document.addEventListener('AppleIDSignInOnFailure', (event) => {
            // Handle error.
            //console.log(event.detail.error);
        });

        this.loadConfig();

        if (Helper.IsAuthenticated()) {
            this.getFavouriteEventList();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { isRegisterSuccess: prevIsRegisterSuccess, isLoginSuccess: prevIsLoginSuccess, isForgotPwdSuccess: prevIsForgotPwdSuccess } = prevProps;
        const { isGoogleLoginSuccess: prevIsGoogleLoginSuccess, isFacebookLoginSuccess: prevIsFacebookLoginSuccess } = prevProps;
        const { isRegisterSuccess, isLoginSuccess, isForgotPwdSuccess, isGoogleLoginSuccess, isFacebookLoginSuccess } = this.props;
        const { isRegisterError: prevIsRegisterError, isLoginError: prevIsLoginError, isForgotPwdError: prevIsForgotPwdError } = prevProps;
        const { isGoogleLoginError: prevIsGoogleLoginError, isFacebookLoginError: prevIsFacebookLoginError } = prevProps;
        const { isRegisterError, isLoginError, isForgotPwdError, loginData, userData, message, isGoogleLoginError, isFacebookLoginError } = this.props;
        const { isGetFavouriteEventsSuccess: prevIsGetFavouriteEventsSuccess } = prevProps;
        const { isGetFavouriteEventsSuccess } = this.props;
        const { isRemoveFromFavouriteSuccess: prevIsRemoveFromFavouriteSuccess } = prevProps;
        const { isRemoveFromFavouriteSuccess } = this.props;
        const { sendSMSSuccess: prevSendSMSSuccess, isResendVerificationSuccess: prevIsResendVerificationSuccess, isResendVerificationError: prevIsResendVerificationError } = prevProps;
        const { sendSMSSuccess, isResendVerificationSuccess, isResendVerificationError, resendVerificationData, favouriteEvents } = this.props;

        let _this = this;

        if (prevIsRegisterSuccess === false && isRegisterSuccess) {
            //console.log("userData : " + JSON.stringify(userData));
            this.setState({ showSignup: false });
            alert("A confirmation mail has been sent to your email address. Please verify your account & login");
        }

        if (prevIsLoginSuccess === false && isLoginSuccess) {
            this.setState({ showSignin: false, showSignup: false });

            //this.props.history.push(this.props.location.pathname);
            window.location.href = this.props.location.pathname;
        }

        if (prevIsForgotPwdSuccess === false && isForgotPwdSuccess) {
            this.setState({ showSignin: false });
            alert("A mail has been sent to your email address.");
        }

        if (prevIsGoogleLoginSuccess === false && isGoogleLoginSuccess) {
            this.setState({ showSignin: false, showSignup: false });
        }

        if (prevIsFacebookLoginSuccess === false && isFacebookLoginSuccess) {
            this.setState({ showSignin: false, showSignup: false });
        }

        if (prevIsResendVerificationSuccess === false && isResendVerificationSuccess) {
            ////console.log("resendVerificationData: ", resendVerificationData);
            alert(resendVerificationData.Message[0].split('<br>').join('\n'))
            this.setState({ showSignin: true, showSignup: false, errorMessage: "" });
        }

        if (prevIsRegisterError === false && isRegisterError) {
            this.setState({ errorMessage: userData }, function () {
                setTimeout(function () {
                    _this.setState({ errorMessage: "" });
                }, 5000);
            });
        }

        if (prevIsLoginError === false && isLoginError) {
            if (loginData === "Please verify your email address to login") {
                this.setState({ accountValidationError: true });
            }
            this.setState({ errorMessage: loginData }, function () {
                setTimeout(function () {
                    _this.setState({ errorMessage: "" });
                }, 5000);
            });
        }

        if (prevIsForgotPwdError === false && isForgotPwdError) {
            this.setState({ errorMessage: message }, function () {
                setTimeout(function () {
                    _this.setState({ errorMessage: "" });
                }, 5000);
            });
        }

        if (prevIsGoogleLoginError === false && isGoogleLoginError) {
            this.setState({ errorMessage: userData }, function () {
                setTimeout(function () {
                    _this.setState({ errorMessage: "" });
                }, 5000);
            });
        }

        if (prevIsFacebookLoginError === false && isFacebookLoginError) {
            this.setState({ errorMessage: userData }, function () {
                setTimeout(function () {
                    _this.setState({ errorMessage: "" });
                }, 5000);
            });
        }

        if (prevIsGetFavouriteEventsSuccess === false && isGetFavouriteEventsSuccess) {
            //console.log("got Fav list");
            let _this = this;

            this.setState({ events: favouriteEvents }, function () {
                _this.state.events.forEach((element, index) => {
                    element["showRemoveFavProgress"] = false
                });
            });
            this.setState({ loadFavEvents: false });
        }

        if (prevIsRemoveFromFavouriteSuccess === false && isRemoveFromFavouriteSuccess) {
            let events = [...this.state.events];
            let index = events.findIndex(d => d.Id === this.state.feId);

            if (index !== -1) {
                this.setState(prevState => {
                    const events = [...prevState.events];
                    events[index].showRemoveFavProgress = false;
                    return { events: events };
                });
            }

            this.setState({ show: false, showSignup: false, showSignin: false });
        }

        if (prevSendSMSSuccess === false && sendSMSSuccess) {
            alert("All sent! Check your messages and follow the link!");
            this.setState({ mobileNo: '', showGetApp: false });
        }

        if (prevIsResendVerificationError === false && isResendVerificationError) {
            this.setState({ errorMessage: resendVerificationData }, function () {
                setTimeout(function () {
                    _this.setState({ errorMessage: "" });
                }, 5000);
            });
        }

        ////console.log("prevPropsIsLoginSuccess: " + JSON.stringify(prevProps));
        ////console.log("isLoginSuccess: " + JSON.stringify(this.props));
    }

    loadConfig = () => {
        fetch(window.location.origin + `/config.json`)
            .then((r) => r.json())
            .then((data) => {
                this.setState({
                    googleClientId: data.googleClientId,
                    facebookClientId: data.facebookClientId,
                    appStoreURL: data.appStoreURL,
                    playStoreURL: data.playStoreURL
                });
            });
    }

    getFavouriteEventList = async () => {
        const { userId } = this.state;
        const { getFavouriteEvents } = this.props;
        //console.log("I am here")
        await getFavouriteEvents(userId);
    }

    handleOpen = (popup) => {
        if (popup === 'fav') {
            if (Helper.IsAuthenticated())
                this.getFavouriteEventList();

            this.setState({ show: true, showSignup: false, showSignin: false, loadFavEvents: true });
        }
        else if (popup === 'signup')
            this.setState({ email: '', password: '', username: '', surname: '', mobileNo: '', show: false, showSignup: true, showSignin: false });
        else if (popup === 'signin')
            this.setState({ email: '', password: '', username: '', surname: '', mobileNo: '', show: false, showSignup: false, showSignin: true, showForgotPwdPage: false });
        else if (popup === "forgotpwd")
            this.setState({ email: '', password: '', username: '', surname: '', mobileNo: '', showForgotPwdPage: true });
        else if (popup === "getApp"){
            this.setState({ email: '', password: '', username: '', surname: '', mobileNo: '', show: false, showSignup: false, showSignin: false, showGetApp: true });
        }
    };

    handleClose = (popup) => {
        if (popup === 'fav')
            this.setState({ show: false });
        else if (popup === 'signup')
            this.setState({ email: '', password: '', username: '', surname: '', mobileNo: '', showSignup: false });
        else if (popup === 'signin')
            this.setState({ email: '', password: '', username: '', surname: '', mobileNo: '', showSignin: false });
        else if (popup === 'getApp'){
            this.setState({ email: '', password: '', username: '', surname: '', mobileNo: '', showGetApp: false });
        }
    };

    handleMyTickets = () => {
        this.props.history.push("/profile/2");
    }

    handlecontactClose = e => {
        this.setState({ showContact: false });
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    handleLogin = async (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        const { loginRequest } = this.props;

        let isValid = true;

        const messages = [];

        if (email === "") {
            isValid = false;
            messages.push("- Please enter email\n");
        }
        else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim()))) {
            isValid = false;
            messages.push("- Please enter valid email\n");
        }

        if (password === "") {
            isValid = false;
            messages.push("- Please enter password\n");
        }

        if (!isValid) {
            alert(Helper.arrayToString(messages));
            return;
        }

        var objUser = new Object();

        objUser.Email = email;
        objUser.Password = password;
        objUser.UdId = uuidv4();
        objUser.DeviceCode = uuidv4();
        objUser.Type = 3;

        await loginRequest(objUser);
    }

    handleSignup = async (e) => {
        e.preventDefault();
        const { username, surname, email, password } = this.state;
        const { registerRequest } = this.props;

        let isValid = true;

        const messages = [];

        if (username === "") {
            isValid = false;
            messages.push("- Please enter name\n");
        }

        if (email === "") {
            isValid = false;
            messages.push("- Please enter email\n");
        }
        else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim()))) {
            isValid = false;
            messages.push("- Please enter valid email\n");
        }

        if (password === "") {
            isValid = false;
            messages.push("- Please enter password\n");
        }

        if (!isValid) {
            alert(Helper.arrayToString(messages));
            return;
        }

        var objUser = new Object();

        objUser.Name = username;
        objUser.Surname = surname;
        objUser.Email = email;
        objUser.Password = password;
        objUser.ProfilePictureId = null;
        objUser.DeviceCode = uuidv4();
        objUser.Type = 3;
        objUser.Mode = 1;

        await registerRequest(objUser);
    }

    handleForgotPwd = async (e) => {
        e.preventDefault();
        const { email } = this.state;
        const { forgotPwdRequest } = this.props;

        let isValid = true;

        const messages = [];

        if (email === "") {
            isValid = false;
            messages.push("- Please enter email\n");
        }
        else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim()))) {
            isValid = false;
            messages.push("- Please enter valid email\n");
        }

        if (!isValid) {
            alert(Helper.arrayToString(messages));
            return;
        }

        await forgotPwdRequest(email);
    }

    parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    handleGoogleLogin = async (res) => {
        debugger
        const { googleLoginRequest } = this.props;

        if (res.credential !== null && res.credential !== undefined && res.credential !== "") {
            var json = JSON.parse(Buffer.from(res.credential.split('.')[1], 'base64').toString());

            // //console.log("json: ", json);

            if (json !== null) {
                const googleresponse = {
                    Name: json.given_name,
                    Surname: json.family_name,
                    Email: json.email,
                    Token: "",
                    ProfilePictureId: "",
                    DeviceCode: uuidv4(),
                    Type: 1,
                    Mode: 3

                }
                await googleLoginRequest(googleresponse);
            }
        }
    };

    handleAppleLogin = async (res) => {
        const { googleLoginRequest } = this.props;

        if (!res.error) {

            var decoded = jwt.decode(res.authorization.id_token);

            var json = res.user;

            if (json) {
                const googleresponse = {
                    Name: json.name.firstName,
                    Surname: json.name.lastName,
                    Email: json.email,
                    DeviceCode: uuidv4(),
                    Type: 1,
                    Mode: 2
                }
                await googleLoginRequest(googleresponse);
            } else {
                const googleresponse = {
                    Email: decoded.email,
                    DeviceCode: uuidv4(),
                    Type: 1,
                    Mode: 2
                }
                await googleLoginRequest(googleresponse);
            }
        }
    };

    handleFacebookLogin = async (res) => {
        const { facebookLoginRequest } = this.props;

        if (res.authResponse.accessToken !== undefined) {
            const facebookresponse = {
                Name: res.user.name,
                Email: res.user.email,
                Token: res.user.id,
                ProfilePictureId: "",
                DeviceCode: uuidv4(),
                Token: res.facebookId,
                Type: 1,
                Mode: 4,

            }
            await facebookLoginRequest(facebookresponse);
        }
    };

    handleLogout = () => {
        Helper.Logout();
        this.props.history.push("/");
    }

    handleOrganiserLogin = () => {
        window.open('https://scank.azurewebsites.net', '_blank');
    }

    hanleProfileClick = () => {
        this.props.history.push("/profile");
    }

    handleRemoveFavourite = async (eventId) => {
        const { userId } = this.state;
        const { removeFromFavourite } = this.props;

        let events = [...this.state.events];
        let index = events.findIndex(d => d.Id === eventId);
        this.setState(prevState => {
            const events = [...prevState.events];
            events[index].showRemoveFavProgress = true;
            return { events: events };
        });

        this.setState({ feId: eventId });
        localStorage.setItem("feId", eventId);

        var objEvent = new Object();

        objEvent.EventId = eventId;
        objEvent.UserId = userId;

        await removeFromFavourite(objEvent);
    }

    sendAppLinkSMS = async () => {
        const { mobileNo } = this.state;
        const { sendSMSRequest } = this.props;

        if (mobileNo === "")
            alert("Please enter mobile number");

        var objSMS = new Object();
        objSMS.ToMobileNo = mobileNo;

        await sendSMSRequest(objSMS);
    }

    handleResendVerification = async () => {
        const { email } = this.state;
        const { resendVerificationMailRequest } = this.props;

        let isValid = true;

        const messages = [];

        if (email === "") {
            isValid = false;
            messages.push("- Please enter email\n");
        }
        else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim()))) {
            isValid = false;
            messages.push("- Please enter valid email\n");
        }

        if (!isValid) {
            alert(Helper.arrayToString(messages));
            return;
        }

        var objUser = new Object();

        objUser.Email = email;

        await resendVerificationMailRequest(objUser);
    }

    getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }

        // Detect Android
        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // Other mobile OSes can be added here
        return 'unknown';
    };

    render() {
        const { show, showSignup, showSignin, email, password, username, surname, showForgotPwdPage, errorMessage, accountValidationError, events } = this.state;
        const { googleClientId, facebookClientId, showRemoveFavProgress, showGetApp, mobileNo, appStoreURL, playStoreURL, loadFavEvents } = this.state;
        const { location, showProgress, isRegisterSuccess, isRegisterError, favouriteEvents } = this.props;
        const { isLoginSuccess, isLoginError } = this.props;
        const { isForgotPwdSuccess, isForgotPwdError } = this.props;

        //console.log(googleClientId);
        //console.log(facebookClientId);

        const responseGoogle = (response) => {
            //console.log(response);
            var res = response.profileObj;
            //console.log(res);
            this.handleGoogleLogin(response);
        }

        const responseApple = (response) => {
            //console.log(response);
            //console.log(response.authorization.id_token);
            this.handleAppleLogin(response);
        }

        const responseFacebook = (response) => {
            //console.log(response);
            var res = response.profileObj;
            //console.log(res);
            this.handleFacebookLogin(response);
        }

        return (
            <>
                <ul class="header__events-list list my-2 my-lg-0">
                    <li class="header__events-item heartt">
                        <NavLink to="#" onClick={() => this.handleOpen('fav')} className="header__events-favorite" >
                            {location.pathname === '/' ?
                                <svg class={show ? "heart-icon-red" : "heart-icon-white"} width="27" height="27" viewBox="0 0 597 512" version="1.1">
                                    <g id="#4c4c4cff">
                                        <path
                                            opacity="1.00"
                                            d=" M 159.50 0.00 L 188.36 0.00 C 228.88 3.72 267.58 21.08 298.55 47.24 C 330.49 20.38 370.65 2.38 412.56 0.00 L 439.41 0.00 C 485.66 3.98 531.23 24.72 561.93 60.01 C 583.33 84.42 595.19 116.37 597.00 148.63 L 597.00 170.43 C 594.74 207.83 581.74 243.93 563.24 276.28 C 553.98 292.76 542.19 307.63 530.18 322.17 C 484.90 376.08 428.99 419.48 374.30 463.28 C 365.73 470.08 357.38 477.18 348.58 483.67 C 336.29 493.15 324.69 504.27 309.86 509.65 C 299.54 513.49 287.40 512.15 278.47 505.62 C 208.81 454.94 141.69 400.35 81.35 338.74 C 57.66 314.17 37.53 286.02 22.84 255.18 C 9.28 226.58 0.66 195.36 0.00 163.61 L 0.00 155.43 C 0.55 138.55 2.88 121.58 8.52 105.61 C 18.87 76.11 39.69 50.98 65.17 33.19 C 92.82 13.88 125.82 2.11 159.50 0.00 M 118.00 88.08 C 103.95 96.08 91.76 107.59 83.73 121.69 C 78.32 133.31 75.92 146.17 75.45 158.93 C 75.12 185.43 84.18 211.13 96.83 234.10 C 104.95 248.54 114.14 262.47 125.17 274.87 C 176.25 332.98 238.20 379.91 298.74 427.54 C 357.86 380.55 418.60 334.67 469.16 278.17 C 479.54 266.95 488.30 254.35 496.24 241.32 C 507.90 221.90 517.06 200.64 520.44 178.12 C 523.14 160.83 521.67 142.79 515.44 126.39 C 510.46 115.81 503.12 106.39 494.32 98.72 C 460.24 69.48 407.33 65.98 368.97 88.82 C 363.54 91.99 358.74 96.07 353.60 99.66 C 341.38 109.23 334.42 123.78 322.54 133.71 C 318.22 137.39 313.02 140.07 307.45 141.23 C 298.36 142.74 288.41 142.80 280.36 137.68 C 264.11 127.76 256.58 108.74 241.19 97.81 C 236.42 94.55 231.86 90.95 226.79 88.14 C 193.81 69.34 151.03 69.46 118.00 88.08 Z"
                                        />
                                    </g>
                                </svg>
                                :
                                <svg class={show ? "heart-icon-red" : "heart-icon-grey"} width="27" height="27" viewBox="0 0 597 512" version="1.1">
                                    <g id="#4c4c4cff">
                                        <path
                                            opacity="1.00"
                                            d=" M 159.50 0.00 L 188.36 0.00 C 228.88 3.72 267.58 21.08 298.55 47.24 C 330.49 20.38 370.65 2.38 412.56 0.00 L 439.41 0.00 C 485.66 3.98 531.23 24.72 561.93 60.01 C 583.33 84.42 595.19 116.37 597.00 148.63 L 597.00 170.43 C 594.74 207.83 581.74 243.93 563.24 276.28 C 553.98 292.76 542.19 307.63 530.18 322.17 C 484.90 376.08 428.99 419.48 374.30 463.28 C 365.73 470.08 357.38 477.18 348.58 483.67 C 336.29 493.15 324.69 504.27 309.86 509.65 C 299.54 513.49 287.40 512.15 278.47 505.62 C 208.81 454.94 141.69 400.35 81.35 338.74 C 57.66 314.17 37.53 286.02 22.84 255.18 C 9.28 226.58 0.66 195.36 0.00 163.61 L 0.00 155.43 C 0.55 138.55 2.88 121.58 8.52 105.61 C 18.87 76.11 39.69 50.98 65.17 33.19 C 92.82 13.88 125.82 2.11 159.50 0.00 M 118.00 88.08 C 103.95 96.08 91.76 107.59 83.73 121.69 C 78.32 133.31 75.92 146.17 75.45 158.93 C 75.12 185.43 84.18 211.13 96.83 234.10 C 104.95 248.54 114.14 262.47 125.17 274.87 C 176.25 332.98 238.20 379.91 298.74 427.54 C 357.86 380.55 418.60 334.67 469.16 278.17 C 479.54 266.95 488.30 254.35 496.24 241.32 C 507.90 221.90 517.06 200.64 520.44 178.12 C 523.14 160.83 521.67 142.79 515.44 126.39 C 510.46 115.81 503.12 106.39 494.32 98.72 C 460.24 69.48 407.33 65.98 368.97 88.82 C 363.54 91.99 358.74 96.07 353.60 99.66 C 341.38 109.23 334.42 123.78 322.54 133.71 C 318.22 137.39 313.02 140.07 307.45 141.23 C 298.36 142.74 288.41 142.80 280.36 137.68 C 264.11 127.76 256.58 108.74 241.19 97.81 C 236.42 94.55 231.86 90.95 226.79 88.14 C 193.81 69.34 151.03 69.46 118.00 88.08 Z"
                                        />
                                    </g>
                                </svg>
                            }
                        </NavLink>
                    </li>
                    <li class="header__events-item">
                        {location.pathname === '/' && this.getMobileOperatingSystem()==="unknown" ?
                            <a id="getTheApp" href="#app" class="header__events-btn header__events-btn_app" aria-label="Get the app">
                                <i class="fas fa-mobile-alt header__events-btn_icon"></i>
                                Get the app
                            </a>
                            :
                            <a id="getTheApp" href="#" onClick={() => this.handleOpen('getApp')} class="header__events-btn header__events-btn_app" aria-label="Get the app">
                                <i class="fas fa-mobile-alt header__events-btn_icon"></i>
                                Get the app
                            </a>
                        }
                    </li>

                    <li class="header__events-item">
                        <a class="header__events-btn header__events-btn_event" aria-label="Open dropdown for create event" href="https://scank.azurewebsites.net/" target="_blank">
                            + CREATE EVENT
                        </a>
                    </li>

                    <li class="header__events-item header__events-dropdown">
                        {Helper.IsAuthenticated() ?
                            <NavDropdown title={
                                <img src={userLogin} width="15" height="16" alt="user icon" />
                            } className="header__events-btn_user p0">
                                <div class="dropdown__menu-modal" aria-labelledby="user">
                                    <NavDropdown.Item to="/profile" className="login__up-modal login__user-modal">
                                        <div className="dropdown-item__wrapper" onClick={this.hanleProfileClick}>
                                            <div class="login login__up-login">
                                                <img src={user1} alt="user" class="login__icon" width="14" height="15" />
                                                <h3 class="login__title"><span>{Helper.GetCurrentUser().Name} {Helper.GetCurrentUser().Surname}<br /></span>{Helper.GetCurrentUser().Email}</h3>
                                            </div>
                                            <i class="fas fa-chevron-right buyer__login-arrow"></i>
                                        </div>
                                    </NavDropdown.Item>
                                    <ul class="login__user-lisst list">
                                        <li class="login__list-item">
                                            <a href="#" onClick={() => this.handleMyTickets()} class="login__list-link">
                                                <img src={ticket} width="14" height="14" alt="icon" class="login__list-icon" />                                                                My Tickets
                                            </a>
                                        </li>
                                        <li class="login__list-item">
                                            <a href="#" onClick={() => this.handleOpen('fav')} class="login__list-link">
                                                <img src={heart} width="14" height="12" alt="icon" class="login__list-icon" />                                                                    Saved
                                            </a>
                                        </li>
                                        <li class="login__list-item">
                                            <a onClick={this.handleLogout} class="login__list-link">
                                                <img src={logout} width="14" height="15" alt="icon" class="login__list-icon" />                                                                 Logout
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </NavDropdown>
                            :
                            <NavDropdown id="navSignIn" title={<span>LOGIN / SIGN UP</span>} className="header__events-btn header__events-btn_login p0">
                                <div class="dropdown__menu-modal" aria-labelledby="loginSignin" style={{ textTransform: "none", backgroundColor: "white" }}>
                                    <NavDropdown.Item to="#" id="divSignIn" onClick={() => this.handleOpen('signin')} className="login__up-modal">
                                        <div className="dropdown-item__wrapper">
                                            <div class="login login__up-login">
                                                <img src={user1} alt="user" class="login__icon" width="14" height="15" />
                                                <h3 class="login__title">
                                                    <span>Buyer</span> Log In / Sign up
                                                </h3>
                                            </div>
                                            <i class="fas fa-chevron-right buyer__login-arrow"></i>
                                        </div>
                                        <p class="login__describe">
                                            Become a scanker in seconds.
                                            <br />
                                            Grab tickets to the best events quickly
                                            <br />& safely.
                                        </p>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => this.handleOrganiserLogin()} className="login__up-modal">
                                        <div className="dropdown-item__wrapper">
                                            <div class="login login__up-login">
                                                <img src={user2} width="14" height="15" alt="user" class="login__icon" />
                                                <h3 class="login__title">
                                                    <span>Organiser</span> Log In / Sign up
                                                </h3>
                                            </div>
                                            <i class="fas fa-chevron-right organiser__login-arrow"></i>
                                        </div>
                                        <p class="login__describe">
                                            Create events to remember, sell tickets, monitor
                                            <br />
                                            and manage every aspect of your promotions
                                            <br />
                                            with ease.
                                        </p>
                                    </NavDropdown.Item>
                                </div>
                            </NavDropdown>
                        }
                    </li>
                </ul>
                <Modal show={show} onHide={() => this.handleClose('fav')} className="right saved-events-modals" dialogClassName="favorit__modal-dialog">
                    <Modal.Body>
                        <div class="modal-content-normal favorit__modal-content">
                            <button type="button" class="close modal__close-btn" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <img src={closeIcon} width="22" height="22" alt="Close icon" class="modal__close-icon" onClick={() => this.handleClose('fav')} />
                                </span>
                            </button>
                            {Helper.IsAuthenticated() ?
                                <div class="favorit__content-wrapper" style={{ position: "absolute" }}>
                                    <h3 class="favorit__content-title">Saved Events</h3>
                                    {loadFavEvents ? <img src={loading} style={{ width: "32px", height: "32px" }} /> :
                                        (events ? (
                                            (events.filter(evt => evt.Type !== 2).length > 0 ?
                                                (<div>
                                                    {
                                                        events.filter(evt => evt.Type !== 2).map((f) => (
                                                            <>
                                                                <div class="favorite__cart-wrapper" style={{ marginBottom: "20px", marginLeft: "35px" }}>
                                                                    <img src={f.CoverPictureUrl} alt="event" class="upcoming__cart-image" />
                                                                    <div class="favorite__cart-content">
                                                                        <div class="favorite__cart-box">
                                                                            <h4 class="favorite__cart-title">{f.Title}</h4>
                                                                            <span class="favorite__cart-price">{f.Currency}{f.Price}</span>
                                                                        </div>
                                                                        <div class="favorit__date-box">
                                                                            <img src={calendarIcon} width="18" height="18" alt="calendar" class="favorite__date-icon" />
                                                                            <span class="favorite__date-sun">{Helper.getDayFromDate(f.StartDate)}</span>
                                                                            <span class="favorite__date-april">{Helper.formatDate(f.StartDate)}</span>
                                                                            <span class="favorite__date-year">{Helper.getYearFromDate(f.StartDate)}</span>
                                                                        </div>
                                                                        <div class="favorit__date-box">
                                                                            <img src={locationIcon} width="13" height="15" alt="location" class="favorite__date-icon" />
                                                                            <span class="favorite__date-sun">{(f.Venue) ? f.Venue + "," : "-"}</span>
                                                                            <span class="favorite__date-town">{(f.City) ? f.City : ""}</span>
                                                                        </div>
                                                                        <div style={{ display: "flex" }}>
                                                                            <div class="favorit__date-box">
                                                                                <img src={clockIcon} width="13" height="13" alt="clock" class="favorite__date-icon" />
                                                                                <span class="favorite__date-sun">{Helper.formatTime(f.StartDate)}</span>
                                                                            </div>
                                                                            <div>
                                                                                {f.showRemoveFavProgress ?
                                                                                    <img src={loading} style={{ width: "32px", height: "32px" }} />
                                                                                    :
                                                                                    <button type="button" className={"upcoming__date-link favouriteButt active"} onClick={() => { this.handleRemoveFavourite(f.Id) }}>
                                                                                        <svg width="32" height="28" viewBox="0 0 597 512" version="1.1">
                                                                                            <g id="#4c4c4cff">
                                                                                                <path fill="#4c4c4c" opacity="1.00" d=" M 159.50 0.00 L 188.36 0.00 C 228.88 3.72 267.58 21.08 298.55 47.24 C 330.49 20.38 370.65 2.38 412.56 0.00 L 439.41 0.00 C 485.66 3.98 531.23 24.72 561.93 60.01 C 583.33 84.42 595.19 116.37 597.00 148.63 L 597.00 170.43 C 594.74 207.83 581.74 243.93 563.24 276.28 C 553.98 292.76 542.19 307.63 530.18 322.17 C 484.90 376.08 428.99 419.48 374.30 463.28 C 365.73 470.08 357.38 477.18 348.58 483.67 C 336.29 493.15 324.69 504.27 309.86 509.65 C 299.54 513.49 287.40 512.15 278.47 505.62 C 208.81 454.94 141.69 400.35 81.35 338.74 C 57.66 314.17 37.53 286.02 22.84 255.18 C 9.28 226.58 0.66 195.36 0.00 163.61 L 0.00 155.43 C 0.55 138.55 2.88 121.58 8.52 105.61 C 18.87 76.11 39.69 50.98 65.17 33.19 C 92.82 13.88 125.82 2.11 159.50 0.00 M 118.00 88.08 C 103.95 96.08 91.76 107.59 83.73 121.69 C 78.32 133.31 75.92 146.17 75.45 158.93 C 75.12 185.43 84.18 211.13 96.83 234.10 C 104.95 248.54 114.14 262.47 125.17 274.87 C 176.25 332.98 238.20 379.91 298.74 427.54 C 357.86 380.55 418.60 334.67 469.16 278.17 C 479.54 266.95 488.30 254.35 496.24 241.32 C 507.90 221.90 517.06 200.64 520.44 178.12 C 523.14 160.83 521.67 142.79 515.44 126.39 C 510.46 115.81 503.12 106.39 494.32 98.72 C 460.24 69.48 407.33 65.98 368.97 88.82 C 363.54 91.99 358.74 96.07 353.60 99.66 C 341.38 109.23 334.42 123.78 322.54 133.71 C 318.22 137.39 313.02 140.07 307.45 141.23 C 298.36 142.74 288.41 142.80 280.36 137.68 C 264.11 127.76 256.58 108.74 241.19 97.81 C 236.42 94.55 231.86 90.95 226.79 88.14 C 193.81 69.34 151.03 69.46 118.00 88.08 Z" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr style={{ marginLeft: "35px" }} />
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                                ) : (<div> <span>No saved events</span> </div>))) : <div>
                                            <img src={loading} style={{ width: "32px", height: "32px" }} />
                                        </div>)}

                                </div>
                                : <div class="favorit__content-wrapper">
                                    <h3 class="favorit__content-title">Saved Events</h3>

                                    <div class="favorite__cart-wrapper">
                                        Please login to view your saved events<br /><a className="blueLink" onClick={() => this.handleOpen('signin')} style={{ color: "#4995f7" }}>login</a>
                                    </div>
                                </div>}
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showSignup} onHide={() => this.handleClose('signup')} className="login" dialogClassName="modal-dialog-centered">
                    <Modal.Body>
                        <div class="modal-content-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <img src={closeIcon} width="22" height="22" alt="Close icon" onClick={() => this.handleClose('signup')} />
                            </button>

                            <div class="login__wrapper">
                                <img src={signup} class="login__img" alt="img" />
                                <div class="login__form modal-form">
                                    <div class="modal-form__box">
                                        <h2 class="modal-form__title">Sign Up</h2>
                                        <p class="modal-form__text signup__text">Join the fam and become an official Scanker in seconds!</p>
                                        <form onSubmit={this.handleSignup}>
                                            <div class="modal-form__wrapper modal-form__wrapper_signup">
                                                <input type="text" class="modal-form__field modal-form__field_signup" placeholder="Name" id="username" name="username" value={username} onChange={this.handleChange} />
                                                <input type="text" class="modal-form__field modal-form__field_signup" placeholder="Surame" id="surname" name="surname" value={surname} onChange={this.handleChange} />
                                                <input type="text" class="modal-form__field" placeholder="Email" name="email" id="email" value={email} onChange={this.handleChange} />
                                                <input type="password" class="modal-form__field" placeholder="Password" id="password" name="password" value={password} onChange={this.handleChange} />
                                            </div>
                                            {showProgress ? <div style={{ textAlign: "center" }}><img src={loading} style={{ width: "32px", height: "32px" }} /></div> :
                                                <button type="submit" class="modal-form__submit modal-form__submit_signup">SIGN UP</button>}
                                        </form>
                                        <div className="errorMessage">{!isRegisterSuccess ? <div>{isRegisterError ? errorMessage : ""}</div> : <Redirect to='/' />}</div>
                                        <div class="modal-form__link">Already have an account? <a className="blueLink" onClick={() => this.handleOpen('signin')} data-toggle="modal" data-dismiss="modal">Sign in</a></div>
                                        <h3 class="modal-form__social-title">Or sign in with</h3>
                                        <div class="modal-form__social-wrapper">
                                            <div>
                                                <img src={googleIcon} title="google login" alt="google" className="social-icon" />
                                            </div>
                                            <GoogleLogin
                                                onSuccess={responseGoogle}
                                                onError={responseGoogle}
                                                type="icon"
                                                size="large"
                                                Shape="rectangular"
                                            />
                                            <span style={{ marginRight: "20px" }}></span>
                                            <AppleLogin
                                                clientId="com.scank.auth.client"
                                                scope="email name"
                                                redirectURI={window.origin + "/"}
                                                responseType="code id_token "
                                                responseMode="form_post"
                                                usePopup={true}
                                                callback={(data, err) => {
                                                    if (data) {
                                                        //console.log("AppleLogin data:", data);
                                                        responseApple(data)
                                                    } else {
                                                        console.log("AppleLogin error:", err)
                                                    }
                                                }
                                                }
                                                render={renderProps => (
                                                    <button
                                                        onClick={renderProps.onClick}
                                                        style={{
                                                            backgroundColor: "white",
                                                            padding: 8,
                                                            border: "1px solid white",
                                                            borderRadius: 5,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            width: 40,
                                                            height: 40
                                                        }}
                                                    >
                                                        <img src={apple} alt="Apple logo" style={{ height: '100%', width: "100%" }} />
                                                    </button>
                                                )}
                                            />
                                            {/*<span style={{ marginRight: "20px" }}></span>*/}
                                            {/*<FacebookLogin*/}
                                            {/*    onClick={() => this.handleFacebookLogin()}*/}
                                            {/*    appId={facebookClientId}*/}
                                            {/*    onSuccess={responseFacebook}*/}
                                            {/*    onFailure={responseFacebook}*/}
                                            {/*    imgSrc={facebook}*/}
                                            {/*    render={renderProps => (*/}
                                            {/*        <a class="modal-form__social-link">*/}
                                            {/*            <img src={facebook} width="25" height="25" alt="facebook" class="modal-form__social-icon" />*/}
                                            {/*        </a>*/}
                                            {/*    )}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showSignin} onHide={() => this.handleClose('signin')} className="login" dialogClassName="modal-dialog-centered">
                    <Modal.Body>
                        <div class="modal-content-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <img src={closeIcon} width="22" height="22" alt="Close icon" onClick={() => this.handleClose('signin')} />
                            </button>

                            <div class="login__wrapper">
                                <img src={login} class="login__img" alt="img" />
                                <div class="login__form modal-form">
                                    <div class="modal-form__box">
                                        {showForgotPwdPage ?
                                            <>
                                                <h2 class="modal-form__title">Forgot Password</h2>
                                                <p class="modal-form__text">&nbsp;</p>
                                                <form onSubmit={this.handleForgotPwd} >
                                                    <div class="modal-form__wrapper">
                                                        <input type="text" class="modal-form__field" placeholder="Email" name="email" id="email" value={email} onChange={this.handleChange} />
                                                    </div>
                                                    {showProgress ? <div style={{ textAlign: "center" }}><img src={loading} style={{ width: "32px", height: "32px" }} /></div> :
                                                        <button type="submit" class="modal-form__submit">Send Email</button>}
                                                </form>
                                                <div className="errorMessage">{!isForgotPwdSuccess ? <div>{isForgotPwdError ? errorMessage : ""}</div> : <Redirect to='/' />}</div>
                                                <div class="modal-form__link"><a className="blueLink" onClick={() => this.handleOpen('signin')} data-toggle="modal" data-dismiss="modal">Back</a></div>
                                            </>
                                            :
                                            <>
                                                <h2 class="modal-form__title">Sign In</h2>
                                                <p class="modal-form__text" style={{ marginBottom: "68px" }}>Enter the portal for the ultimate experience!</p>
                                                <form onSubmit={this.handleLogin} >
                                                    <div class="modal-form__wrapper">
                                                        <input type="text" class="modal-form__field" placeholder="Email" name="email" id="email" value={email} onChange={this.handleChange} />
                                                        <input type="password" class="modal-form__field" placeholder="Password" id="password" name="password" value={password} onChange={this.handleChange} />
                                                        <div class="modal-form__row">
                                                            <div class="custom-control custom-checkbox mr-sm-2">
                                                                <input type="checkbox" class="custom-control-input" id="customControlAutosizing" />
                                                                <label class="custom-control-label" for="customControlAutosizing">Remember me</label>
                                                            </div>
                                                            <a onClick={() => this.handleOpen('forgotpwd')} class="modal-form__row-link">Forgot Password?</a>
                                                        </div>
                                                    </div>
                                                    {showProgress ? <div style={{ textAlign: "center" }}><img src={loading} style={{ width: "32px", height: "32px" }} /></div> :
                                                        <button type="submit" class="modal-form__submit">SIGN IN</button>}
                                                </form>
                                                <div className="errorMessage">{!isLoginSuccess ? <div>{isLoginError ? errorMessage : ""}</div> : <Redirect to='/' />}</div>
                                                {accountValidationError ?
                                                    <div class="modal-form__link"><a className="blueLink" onClick={() => this.handleResendVerification()} data-toggle="modal" data-dismiss="modal">Resend Verification</a></div>
                                                    : null}
                                                <div class="modal-form__link">Don't have an account? <a className="blueLink" onClick={() => this.handleOpen('signup')} data-toggle="modal" data-dismiss="modal">Sign up</a></div>
                                                <div class="modal-form__social">
                                                    <h3 class="modal-form__social-title">Or sign in with</h3>
                                                    <div class="modal-form__social-wrapper">
                                                        {/* <div>
                                                            <img src={googleIcon} title="google login" alt="google" className="social-icon" />
                                                        </div> */}
                                                        <GoogleLogin
                                                            onSuccess={responseGoogle}
                                                            onError={responseGoogle}
                                                            type="icon"
                                                            size="large"
                                                            Shape="rectangular"
                                                        />
                                                        <span style={{ marginRight: "20px" }}></span>
                                                        <AppleLogin
                                                            clientId="com.scank.auth.client"
                                                            scope="email name"
                                                            redirectURI={window.origin + "/"}
                                                            responseType="code id_token "
                                                            responseMode="form_post"
                                                            usePopup={true}
                                                            callback={(data, err) => {
                                                                if (data) {
                                                                    responseApple(data)
                                                                } else {
                                                                    console.log("AppleLogin error:", err)
                                                                }
                                                            }
                                                            }
                                                            render={renderProps => (
                                                                <button
                                                                    onClick={renderProps.onClick}
                                                                    style={{
                                                                        backgroundColor: "white",
                                                                        padding: 8,
                                                                        border: "1px solid white",
                                                                        borderRadius: 5,
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        width: 40,
                                                                        height: 40
                                                                    }}
                                                                >
                                                                    <img src={apple} alt="Apple logo" style={{ height: '100%', width: "100%" }} />
                                                                </button>
                                                            )}
                                                        />
                                                        {/*<span style={{ marginRight: "20px" }}></span>*/}
                                                        {/*<FacebookLogin*/}
                                                        {/*    onClick={() => this.handleFacebookLogin()}*/}
                                                        {/*    appId={facebookClientId}*/}
                                                        {/*    onSuccess={responseFacebook}*/}
                                                        {/*    onFailure={responseFacebook}*/}
                                                        {/*    imgSrc={facebook}*/}
                                                        {/*    render={renderProps => (*/}
                                                        {/*        <a class="modal-form__social-link">*/}
                                                        {/*            <img src={facebook} width="25" height="25" alt="facebook" class="modal-form__social-icon" />*/}
                                                        {/*        </a>*/}
                                                        {/*    )}*/}
                                                        {/*/>*/}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showGetApp} onHide={() => this.handleClose('getApp')} className="login" dialogClassName="modal-dialog-centered">
                    <Modal.Body>
                        <div class="modal-content-body">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <img src={closeIcon} width="22" height="22" alt="Close icon" onClick={() => this.handleClose('getApp')} />
                            </button>
                            <div class="login__wrapper">
                                <div class="login__form modal-form" style={{ width: "100%" }}>
                                    <div class="modal-form__box" style={{ maxWidth: "750px", textAlign: "center" }}>
                                        <div style={{ maxWidth: "450px", margin: "0 auto" }}>
                                            <h2 class="modal-form__title">Get The App</h2>
                                        </div>
                                        <div style={{ maxWidth: "750px" }}>
                                            <p style={{ maxWidth: `${(window.matchMedia('(max-width: 600px)').matches) ? '357px' : ''}` }}  class="tickets__content-app">
                                                Download the Scank App today for The Ultimate Experience!
                                            </p>
                                            <div class="qrcodeflx" style={{ justifyContent: "center" }}>
                                                <div style={{ marginRight: "20px" }}>
                                                    <img width={150} src={this.getMobileOperatingSystem() === "unknown" ? organizerQRCode : qrcode1} onClick={(e) => {
                                                        e.preventDefault(); // Prevents the default action
                                                        const os = this.getMobileOperatingSystem();
                                                        if (os === "iOS") {
                                                            window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                                                        } else if (os === "Android") {
                                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                                                        } else {
                                                            console.log("Unsupported OS or unknown")
                                                        }
                                                        // For other cases, do nothing
                                                    }}></img>
                                                    <p className="mt-2" onClick={(e) => {
                                                        e.preventDefault(); // Prevents the default action
                                                        const os = this.getMobileOperatingSystem();
                                                        if (os === "iOS") {
                                                            window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                                                        } else if (os === "Android") {
                                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                                                        } else {
                                                            console.log("Unsupported OS or unknown")
                                                        }
                                                        // For other cases, do nothing
                                                    }} >Organise events!</p>
                                                </div>
                                                <div style={{ marginLeft: "20px" }}>
                                                    <img width={150} src={this.getMobileOperatingSystem() === "unknown" ? mainAppQRCode : qrcode2} onClick={(e) => {
                                                        e.preventDefault(); // Prevents the default action
                                                        const os = this.getMobileOperatingSystem();
                                                        if (os === "iOS") {
                                                            window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                                                        } else if (os === "Android") {
                                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                                                        } else {
                                                            console.log("Unsupported OS or unknown")
                                                        }
                                                        // For other cases, do nothing
                                                    }} ></img>
                                                    <p className="mt-2" onClick={(e) => {
                                                        e.preventDefault(); // Prevents the default action
                                                        const os = this.getMobileOperatingSystem();
                                                        if (os === "iOS") {
                                                            window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                                                        } else if (os === "Android") {
                                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                                                        } else {
                                                            console.log("Unsupported OS or unknown")
                                                        }
                                                        // For other cases, do nothing
                                                    }} >Grab Tickets!</p>
                                                </div>
                                            </div>
                                            <div class="tickets__social-wrapper">
                                                <a href="https://apps.apple.com/gb/app/scank/id1574492173" class="tickets__social-link">
                                                    <img src={appStore} width="120" height="31" alt="App Store" class="tickets__social-icon" />
                                                </a>
                                                <a href="https://play.google.com/store/apps/details?id=com.scank.organizer" class="tickets__social-link">
                                                    <img src={playStore} width="110" height="31" alt="Google Play Store" class="tickets__social-icon" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        showProgress: state.authReducer.showProgress,
        isRegisterSuccess: state.authReducer.isRegisterSuccess,
        isRegisterError: state.authReducer.isRegisterError,
        userData: state.authReducer.userData,
        isLoginSuccess: state.authReducer.isLoginSuccess,
        isLoginError: state.authReducer.isLoginError,
        loginData: state.authReducer.loginData,
        isForgotPwdSuccess: state.authReducer.isForgotPwdSuccess,
        isForgotPwdError: state.authReducer.isForgotPwdError,
        message: state.authReducer.message,
        isGetFavouriteEventsSuccess: state.eventReducer.isGetFavouriteEventsSuccess,
        isGetFavouriteEventsError: state.eventReducer.isGeFilteredEventsError,
        favouriteEvents: state.eventReducer.favouriteEvents,
        isGoogleLoginSuccess: state.authReducer.isGoogleLoginSuccess,
        isGoogleLoginError: state.authReducer.isGoogleLoginError,
        isFacebookLoginSuccess: state.authReducer.isFacebookLoginSuccess,
        isFacebookLoginError: state.authReducer.isFacebookLoginError,
        isRemoveFromFavouriteSuccess: state.eventReducer.isRemoveFromFavouriteSuccess,
        isRemoveFromFavouriteError: state.eventReducer.isRemoveFromFavouriteError,
        removedFromFavourite: state.eventReducer.removedFromFavourite,
        sendSMSSuccess: state.eventReducer.sendSMSSuccess,
        sendSMSError: state.eventReducer.sendSMSError,
        smsResponse: state.eventReducer.smsResponse,
        isResendVerificationSuccess: state.authReducer.isResendVerificationSuccess,
        isResendVerificationError: state.authReducer.isResendVerificationError,
        resendVerificationData: state.authReducer.resendVerificationData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        registerRequest: (data) => dispatch(registerRequest(data)),
        loginRequest: (data) => dispatch(loginRequest(data)),
        forgotPwdRequest: (data) => dispatch(forgotPwdRequest(data)),
        getFavouriteEvents: (id) => dispatch(getFavouriteEvents(id)),
        googleLoginRequest: (data) => dispatch(googleLoginRequest(data)),
        facebookLoginRequest: (data) => dispatch(facebookLoginRequest(data)),
        removeFromFavourite: (data) => dispatch(removeFromFavourite(data)),
        sendSMSRequest: (data) => dispatch(sendSMSRequest(data)),
        resendVerificationMailRequest: (data) => dispatch(resendVerificationMailRequest(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserActions));