import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.svg";
import sitelogo from "../../images/site-logo.png";
import user1 from "../../images/user_1.svg";
import user2 from "../../images/user_2.svg";
import closeIcon from "../../images/close.svg";
import { DropdownButton, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle";
import UserActions from '../userActions';
import facebook from "../../images/facebook.svg";
import twitter from "../../images/twitter.svg";
import instagram from "../../images/instagram.svg";
import letter from "../../images/letter.svg";
import whatsap from "../../images/whatsap.svg";
import tiktok from "../../images/TICKTOK.png";
import newwhatsapp from "../../images/WHATSAPP.png"
import dots from "../../images/dotss.png"
import searchgrey from "../../images/search-grey-icon.png";
import { getSiteContent, getPressFile, getIsModalOpen } from "../../redux/actions/actions";
import qrcode1 from '../../images/organize-app.png';
import qrcode2 from '../../images/booking-app.png';
import mainAppQRCode from "../../images/mainAppQRCode.png";
import organizerQRCode from "../../images/organizerQRCode.png";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showContact: false,
            show: false,
            menu: false,
            display: false,
            showSearch: false,
            searchEvtName: ''
        };
    }

    componentDidMount() {
        setTimeout(this.getPressLink(), 5000);
        // console.log(document.getElementById("scrollUp"))
        if (document.getElementById("scrollUp") && document.documentElement.scrollTop <= 150)
            document.getElementById("scrollUp").style.display = "none";
        else if (document.getElementById("scrollUp"))
            document.getElementById("scrollUp").style.display = "";

        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (document.getElementById("scrollUp") && document.documentElement.scrollTop <= 150)
            document.getElementById("scrollUp").style.display = "none";
        else if (document.getElementById("scrollUp"))
            document.getElementById("scrollUp").style.display = "";
    };

    getPressLink = async () => {
        const { getPressFile } = this.props;

        var pFile = new Object();
        pFile.title = 'PressFile';

        await getPressFile(pFile);
    }

    handleOpen = () => {
        this.setState({ display: true });
    };

    handleClose = () => {
        this.setState({ display: false });
    };

    handlecontactOpen = e => {
        this.setState({ showContact: true });
    };

    handlecontactClose = e => {
        this.setState({ showContact: false });
    };

    toggleMenu = () => {
        const { getIsModalOpen } = this.props;
        if(!this.state.menu && window.location.pathname.split('/')[1] === 'tickets'){
            getIsModalOpen(true);
            // localStorage.setItem("isModalOpen", true);
        }else{
            getIsModalOpen(false);
            // localStorage.setItem("isModalOpen", false);
        }
        this.setState({ menu: !this.state.menu })
    }

    handleChange = (e) => {
        debugger
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    handleSearchOpen = e => {
        
        this.setState({ showSearch: true }, function () {
            document.getElementById("searchEvtName").focus();
        });
    };

    handleSearchClose = e => {
        this.setState({ showSearch: false });
    };

    handleEnter = (e) => {
        if (e.key === "Enter")
            this.handleSearch();
    };

    handleSearch = () => {
        const { searchEvtName } = this.state;

        this.setState({ show: false, showSearch: false });

        if (window.location.pathname === "/events")
            window.location.reload();

        this.props.history.push({
            pathname: "/events",
            state: {
                eName: searchEvtName
            },
        });
    }

    getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'iOS';
        }

        // Detect Android
        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // Other mobile OSes can be added here
        return 'unknown';
    };

    render() {
        const { show, showContact, showSearch, searchEvtName } = this.state;
        const display = (this.state.menu) ? "show" : "";
        const { pressFile } = this.props;

        return (
            <>
                <div className="header header__pages">
                    <nav class="navbar navbar-expand-xl navbar-light sticky whiteBg" id="nav">
                        <a class="navbar-brand" href="/">
                            <img src={sitelogo} alt="SCANK" class="navbar__nav-logo" />
                        </a>
                        <button
                            class="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbar"
                            aria-controls="navbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={this.toggleMenu}
                        >
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class={"collapse navbar-collapse hight  " + display} id="navbar">
                            <ul class="navbar-nav mr-auto ulalign">
                                <li class="nav-item">
                                    <a class="nav-link-header clrtxtres " href="/">
                                        Home
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link-header clrtxtres" href="/events">
                                        Events
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link-header clrtxtres" href="/about">
                                        About
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link-header clrtxtres" onClick={this.handlecontactOpen} data-toggle="modal">
                                        Contact
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link-header clrtxtres" onClick={this.handleSearchOpen} data-toggle="modal">
                                        <img class="searchMenuImg" width="20" height="20" />&nbsp;<span class="serachMenu">Find an event</span>
                                    </a>
                                </li>
                            </ul>
                            <UserActions />

                        </div>
                    </nav>
                </div>
                <Modal
                    show={show}
                    className="right"
                    dialogClassName="favorit__modal-dialog"
                >
                    <Modal.Body>
                        <div class="modal-content favorit__modal-content">
                            <button
                                type="button"
                                class="close modal__close-btn"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <img
                                        src={closeIcon}
                                        width="22"
                                        height="22"
                                        alt="Close icon"
                                        class="modal__close-icon"
                                        onClick={this.handleClose}
                                    />
                                </span>
                            </button>

                            <div class="favorit__content-wrapper">
                                <h3 class="favorit__content-title">Saved Events</h3>

                                <div class="favorite__cart-wrapper">
                                    <img
                                        src="../../images/favorite-cart-image.jpg"
                                        alt="man"
                                        class="favorite-cart-image"
                                    />
                                    <div class="favorite__cart-content">
                                        <div class="favorite__cart-box">
                                            <h4 class="favorite__cart-title">Wizkid World Tour</h4>
                                            <span class="favorite__cart-price">£10</span>
                                        </div>
                                        <div class="favorit__date-box">
                                            <img
                                                src="../../images/calendar_ic.svg"
                                                width="18"
                                                height="18"
                                                alt="calendar"
                                                class="favorite__date-icon"
                                            />
                                            <span class="favorite__date-sun">Sun</span>
                                            <span class="favorite__date-april">April 25</span>
                                            <span class="favorite__date-year">2021</span>
                                        </div>
                                        <div class="favorit__date-box">
                                            <img
                                                src="../../images/location-pin.svg"
                                                width="13"
                                                height="15"
                                                alt="location"
                                                class="favorite__date-icon"
                                            />
                                            <span class="favorite__date-sun">Wembly Arena,</span>
                                            <span class="favorite__date-town">London</span>
                                        </div>
                                        <div class="favorit__date-box">
                                            <img
                                                src="../../images/clock.svg"
                                                width="13"
                                                height="13"
                                                alt="clock"
                                                class="favorite__date-icon"
                                            />
                                            <span class="favorite__date-sun">9:00pm</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showContact} onHide={this.handlecontactClose} className="login" dialogClassName="modal-dialog-centered"  >
                    <Modal.Body>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ textAlign: "right", margin: "10px", top: "8px" }}>
                            <img src={closeIcon} width="30" height="30" alt="Close icon" onClick={() => this.handlecontactClose()} />
                        </button>
                        <div className="modal-dialog contacts__modal-dialog" style={{paddingTop: "20px"} }>

                            <div className="modal-content-body contacts__modal-content" style={{ overflow: "inherit" }}>
                                <div className="contacts__container">

                                    <div className="contacts__logo-wrapper">
                                        <img
                                            src={sitelogo}
                                            alt="Contacts Logotype"
                                            className="contacts__logo-image"
                                        />
                                    </div>
                                    <div className="contacts__content-box">
                                        <div className="contacts__touch-wrapper">
                                            {/* <h4 className="contacts__touch-title">Get in touch</h4>
                                                <p className="contacts__touch-description">
                                                    For any queries just give us a shout!
                                                </p> */}
                                            {/* <a href="#" className="contacts__touch-link">
                                                    <img
                                                        src={letter}
                                                        width={34}
                                                        height={12}
                                                        alt="icon"
                                                        className="touch__link-icon"
                                                    />
                                                    Info@scank.co.uk
                                                </a> */}
                                            <a
                                                href="https://wa.me/+447379296347" target="_blank"
                                                className="contacts__touch-tel"
                                                aria-label="phone number"
                                            >
                                                <div style={{ width: "200px" }}>
                                                    <img
                                                        src={newwhatsapp}
                                                        alt="icon"
                                                        className="contacts__tel-icon"
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "-20px" }}>
                                                    <h3 class="h3whatsapp">What's up? Let's talk</h3>
                                                    <p class="pwhatsapp">Click to connect directly to our Whatsapp</p>
                                                    <img
                                                        src={dots}
                                                        width={"60%"}
                                                        alt="icon"
                                                    />
                                                </div>
                                            </a>
                                            <div className="links-section">
                                                <div>
                                                    <a href="mailto:hello@scank.co.uk" className="contacts__touch-link emaillink">
                                                        <img
                                                            src={letter}
                                                            width={34}
                                                            height={12}
                                                            alt="icon"
                                                            className="touch__link-icon"
                                                        />
                                                        Hello@scank.co.uk
                                                    </a>
                                                </div>
                                                <div>
                                                    {/* <div class="whtsapp">
                                                    <WhatsAppWidget companyName="Scank" replyTimeText="hello"  phoneNumber="+447932462118" />
                                                    </div> */}
                                                        <ul className="contacts__touch-list list" style={{ paddingLeft: "14px" }}>
                                                        <li className="contacts__list-item">
                                                            <a href="/terms" className="contacts__list-link">
                                                                Terms
                                                            </a>
                                                        </li>
                                                        <li className="contacts__list-item">
                                                            <a href="/privacy" className="contacts__list-link">
                                                                Privacy Policy
                                                            </a>
                                                        </li>
                                                        <li className="contacts__list-item">
                                                            <a href={pressFile} className="contacts__list-link">
                                                                Press
                                                            </a>
                                                        </li>
                                                        <li className="contacts__list-item">
                                                            <a href="/faq" className="contacts__list-link">
                                                                Faqs
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contacts__touch-wrapper" >
                                            <h4 className="contacts__follow-title" style={{ paddingLeft: "14px" }}>Get The App</h4>
                                            <p class="contacts__follow-description" style={{ marginBottom: "20px", paddingLeft: "14px" }}>
                                                Download the Scank App today for The Ultimate Experience!
                                            </p>
                                            <div class="qrcodeflx" style={{ paddingLeft: "14px" }}>
                                                <div style={{ marginRight: "20px" }}>
                                                    <img width={90} src={this.getMobileOperatingSystem() === "unknown" ? organizerQRCode : qrcode1} onClick={(e) => {
                                                        e.preventDefault(); // Prevents the default action
                                                        const os = this.getMobileOperatingSystem();
                                                        if (os === "iOS") {
                                                            window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                                                        } else if (os === "Android") {
                                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                                                        } else {
                                                            console.log("Unsupported OS or unknown")
                                                        }
                                                        // For other cases, do nothing
                                                    }}></img>
                                                    <p className="mt-2" onClick={(e) => {
                                                        e.preventDefault(); // Prevents the default action
                                                        const os = this.getMobileOperatingSystem();
                                                        if (os === "iOS") {
                                                            window.location.href = "https://apps.apple.com/gb/app/scankorganizers/id1608159730";
                                                        } else if (os === "Android") {
                                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.organizer";
                                                        } else {
                                                            console.log("Unsupported OS or unknown")
                                                        }
                                                        // For other cases, do nothing
                                                    }} style={{ fontSize: "small" }}>Organise events!</p>
                                                </div>
                                                <div style={{ marginLeft: "20px" }}>
                                                    <img width={90} src={this.getMobileOperatingSystem() === "unknown" ? mainAppQRCode : qrcode2} onClick={(e) => {
                                                        e.preventDefault(); // Prevents the default action
                                                        const os = this.getMobileOperatingSystem();
                                                        if (os === "iOS") {
                                                            window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                                                        } else if (os === "Android") {
                                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                                                        } else {
                                                            console.log("Unsupported OS or unknown")
                                                        }
                                                        // For other cases, do nothing
                                                    }}></img>
                                                    <p className="mt-2" onClick={(e) => {
                                                        e.preventDefault(); // Prevents the default action
                                                        const os = this.getMobileOperatingSystem();
                                                        if (os === "iOS") {
                                                            window.location.href = "https://apps.apple.com/gb/app/scank/id1574492173";
                                                        } else if (os === "Android") {
                                                            window.location.href = "https://play.google.com/store/apps/details?id=com.scank.smartapps";
                                                        } else {
                                                            console.log("Unsupported OS or unknown")
                                                        }
                                                        // For other cases, do nothing
                                                    }} style={{ fontSize: "small" }}>Grab Tickets!</p>
                                                </div>
                                            </div>
                                            <ul className="contacts__follow-social list" style={{ paddingLeft: "14px" }}>
                                                <li className="contacts__social-item">
                                                    <a href="https://twitter.com/scankworld" className="contacts__social-link" target="_blank">
                                                        <img
                                                            src={twitter}
                                                            width={20}
                                                            height={13}
                                                            alt="Twitter icon"
                                                            className="contacts__social-icon"
                                                            aria-label="Twitter"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="contacts__social-item">
                                                    <a href="https://www.instagram.com/scankapp/" className="contacts__social-link" target="_blank">
                                                        <img
                                                            src={instagram}
                                                            width={16}
                                                            height={16}
                                                            alt="Instagram icon"
                                                            className="contacts__social-icon"
                                                            aria-label="Instagram"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="contacts__social-item">
                                                    <a href="https://www.tiktok.com/@scankworld" className="contacts__social-link" target="_blank">
                                                        <img
                                                            src={tiktok}
                                                            width={16}
                                                            height={16}
                                                            alt="Instagram icon"
                                                            className="contacts__social-icon"
                                                            aria-label="Instagram"
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showSearch} onHide={this.handleSearchClose} className="contact_modal"  >
                    <Modal.Body>

                        <div id="contacts" tabIndex={-1} aria-hidden="true" style={{ height: "100%" }}>
                            <div className="modal-dialog contacts__modal-dialog" style={{pointerEvents:"all"}}>
                                <div className="modal-content-body contacts__modal-content">
                                    <button type="button" class="close modal__close-btn" data-dismiss="modal" aria-label="Close" onClick={() => this.handleSearchClose()}>
                                        <span aria-hidden="true">
                                            <img src={closeIcon} width="22" height="22" alt="Close icon" class="modal__close-icon" />
                                        </span>
                                    </button>
                                    <div className="contacts__container" data-keyboard="false">
                                        <div className="contacts__content-box" style={{ display: "block", textAlign: "center" }}>
                                            <div className="contacts__touch-wrapper" style={{ fontSize: "17px", fontWeight: "600", marginBottom: "5px", color: "#353535" }}>
                                                THE ULTIMATE EXPERIENCE
                                            </div>
                                            <div className="contacts__touch-wrapper" style={{ display: "contents" }}>
                                                <input type="text" placeholder="Search an event..." name="search" style={{ textAlign: "center", border: "1px solid #353535", borderRadius: "20px", width: "350px", height: "40px" }} onKeyDown={(e) => {
                                                    this.handleEnter(e);
                                                }} id="searchEvtName" name="searchEvtName" value={searchEvtName} onChange={this.handleChange} onBlur={this.handleSearchClose} />
                                                <button type="submit" style={{ display: "none", background: "#e5e5e5", float: "left", border: "none" }} onClick={this.handleSearch}><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        isGetPressFileSuccess: state.siteContentReducer.isGetPressFileSuccess,
        isGetPressFileError: state.siteContentReducer.isGetPressFileError,
        pressFile: state.siteContentReducer.pressFile,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getSiteContent: (data) => dispatch(getSiteContent(data)),
        getPressFile: (data) => dispatch(getPressFile(data)),
        getIsModalOpen: (data) => dispatch(getIsModalOpen(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
